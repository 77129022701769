import { Link, useStaticQuery, graphql } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../components/service-hero";
import ImageSideContainer from "../components/image-side-container";
import { Button } from "react-bootstrap";
import CareerForm from "../components/career-form";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const CareersPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpJobOpportunity {
        nodes {
          title
          slug
          jobFields {
            description
            details
            requirements
            salary
            image {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          tags {
            nodes {
              name
            }
          }
        }
      }
      pageData: wpPage(slug: { eq: "careers" }) {
        careersFields {
          careersBanner {
            careersBannerHeading
            careersBannerBackgroundImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          whyWorkCta {
            whyWorkCtaHeading
            whyWorkCtaContent
            whyWorkCtaButton1 {
              title
              target
              url
            }
            whyWorkCtaButton2 {
              title
              target
              url
            }
          }
          careersBackgroundImageOverlayContent {
            careersOverlayContentHeading
            careersOverlayContentText
            careersBackgroundImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          careersVacanciesSection {
            careersVacanciesHeading
            careersVacanciesContent
            careersVacancies {
              careersVacancyHeading
              careersVacancyIcon {
                node {
                  altText
                  sourceUrl
                }
              }
            }
          }
          careersOffersSection {
            careersOffersHeading
            careersOffers {
              careersOfferHeading
              careersOfferIcon {
                node {
                  altText
                  sourceUrl
                }
              }
            }
          }
          careersContentSection {
            careersContentHeading
            careersContentText
          }
          careersCurrentVacancies {
            careersCurrentVacanciesHeading
            careersCurrentVacanciesButton2 {
              title
              target
              url
            }
            careersCurrentVacanciesButton1 {
              title
              target
              url
            }
          }
          careersLeftContentRightImage {
            careersLeftContentHeading
            careersLeftContentText
            careersRightImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          careersFormSection {
            careersFormHeading
          }
          careersContentCta {
            careersContentCtaHeading
            careersContentCtaText
            careersContentCtaButton1 {
              target
              title
              url
            }
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const {
    pageData: { seoFields, careersFields },
  } = data;

  const {
    careersBanner,
    whyWorkCta,
    careersBackgroundImageOverlayContent,
    careersVacanciesSection,
    careersOffersSection,
    careersContentSection,
    careersCurrentVacancies,
    careersLeftContentRightImage,
    careersFormSection,
    careersContentCta,
  } = careersFields;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const alternatingColor = ["bg-primary", "bg-purple", "bg-secondary"];

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Careers",
        item: {
          url: `${siteUrl}/careers`,
          id: `${siteUrl}/careers`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      {careersBanner && (
        <section>
          <ServiceHero
            title={careersBanner.careersBannerHeading}
            backgroundImage={
              careersBanner.careersBannerBackgroundImage.node?.localFile
                .childImageSharp.gatsbyImageData
            }
          />
        </section>
      )}
      {whyWorkCta &&
        !checkPropertiesForNull(whyWorkCta, ["whyWorkCtaHeading"]) && (
          <section className="py-5  py-lg-7 position-relative">
            <Container>
              <Row>
                <Col className="position-relative text-center">
                  <h2 className=" text-center pb-5 text-uppercase">
                    {whyWorkCta.whyWorkCtaHeading}
                  </h2>
                  <SafeHtmlParser htmlContent={whyWorkCta?.whyWorkCtaContent} />
                  {whyWorkCta.whyWorkCtaButton1 &&
                    whyWorkCta.whyWorkCtaButton1.url && (
                      <Button
                        style={{ borderRadius: "0px" }}
                        variant="secondary"
                        href={whyWorkCta.whyWorkCtaButton1.url}
                        className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
                        target={whyWorkCta.whyWorkCtaButton1.target ?? "_self"}
                      >
                        {whyWorkCta.whyWorkCtaButton1.title}
                      </Button>
                    )}

                  {whyWorkCta.whyWorkCtaButton2 &&
                    whyWorkCta.whyWorkCtaButton2.url && (
                      <Button
                        style={{ borderRadius: "0px" }}
                        variant="outline-secondary"
                        href={whyWorkCta.whyWorkCtaButton2.url}
                        className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
                        target={whyWorkCta.whyWorkCtaButton2.target ?? "_self"}
                      >
                        {whyWorkCta.whyWorkCtaButton2.title}
                      </Button>
                    )}
                </Col>
              </Row>
            </Container>
          </section>
        )}

      {careersBackgroundImageOverlayContent &&
        !checkPropertiesForNull(careersBackgroundImageOverlayContent, [
          "careersOverlayContentHeading",
        ]) && (
          <section>
            {" "}
            <BgImage
              style={{ backgroundPosition: "top" }}
              className=""
              alt=""
              image={getImage(
                careersBackgroundImageOverlayContent.careersBackgroundImage.node
                  ?.localFile.childImageSharp.gatsbyImageData
              )}
            >
              <Container
                style={{ zIndex: 2 }}
                className="position-relative py-xl-7 py-5 "
              >
                <Row className="justify-content-end">
                  <Col lg={7} className="">
                    <div className="position-relative  p-5">
                      <div
                        style={{
                          zIndex: 1,
                          opacity: 0.8,
                          backgroundColor: "white",
                        }}
                        className="position-absolute h-100 w-100 start-0 bottom-0"
                      ></div>
                      <div className="position-relative" style={{ zIndex: 2 }}>
                        <h2 className=" text-uppercase pb-4">
                          {
                            careersBackgroundImageOverlayContent.careersOverlayContentHeading
                          }
                        </h2>

                        <SafeHtmlParser htmlContent={careersBackgroundImageOverlayContent?.careersOverlayContentText} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </BgImage>
          </section>
        )}
      {careersVacanciesSection &&
        !checkPropertiesForNull(careersVacanciesSection, [
          "careersVacanciesHeading",
        ]) && (
          <section className="py-5  py-lg-7 position-relative">
            <Container>
              <Row className="align-items-center">
                <Col lg={6} className="position-relative ">
                  <h2 className="  pb-4 text-uppercase">
                    {careersVacanciesSection.careersVacanciesHeading}
                  </h2>
                  <SafeHtmlParser htmlContent={careersVacanciesSection?.careersVacanciesContent} />
                </Col>
                {careersVacanciesSection.careersVacancies &&
                  careersVacanciesSection.careersVacancies.length > 0 && (
                    <Col lg={6}>
                      <Row className="justify-content-center">
                        {careersVacanciesSection.careersVacancies.map(
                          (careerItem) => (
                            <Col className="text-center" xs={6} key={uuidv4()}>
                              {" "}
                              <img
                                src={
                                  careerItem.careersVacancyIcon.node?.sourceUrl
                                }
                                alt={
                                  careerItem.careersVacancyIcon.node?.altText
                                }
                                className="pb-3 "
                                style={{
                                  maxWidth: "100px",
                                }}
                              />
                              <p className="inter-bold fs-5">
                                {careerItem.careersVacancyHeading}
                              </p>
                            </Col>
                          )
                        )}
                      </Row>
                    </Col>
                  )}
              </Row>
            </Container>
          </section>
        )}

      {careersOffersSection &&
        !checkPropertiesForNull(careersOffersSection, [
          "careersOffersHeading",
        ]) && (
          <section className="gradient py-5 py-lg-7">
            <Container>
              <Row>
                <Col>
                  <h2 className=" text-center text-white pb-5 text-uppercase">
                    {careersOffersSection.careersOffersHeading}
                  </h2>
                </Col>
              </Row>
              {careersOffersSection.careersOffers &&
                careersOffersSection.careersOffers.length > 0 && (
                  <Row>
                    {careersOffersSection.careersOffers.map((item) => (
                      <Col className="text-center" xs={6} lg={3} key={uuidv4()}>
                        <img
                          src={item.careersOfferIcon.node?.sourceUrl}
                          alt={item.careersOfferIcon.node?.altText}
                          className="pb-3 "
                          style={{
                            maxWidth: "150px",
                          }}
                        />
                        <p className="fs-4 inter-bold text-white">
                          {item.careersOfferHeading}
                        </p>
                      </Col>
                    ))}
                  </Row>
                )}
            </Container>
          </section>
        )}

      {careersContentSection &&
        !checkPropertiesForNull(careersContentSection, [
          "careersContentHeading",
        ]) && (
          <section className="pt-5  pt-lg-7 position-relative">
            <Container>
              <Row>
                <Col className="position-relative text-center">
                  <h2 className=" text-center pb-5 text-uppercase">
                    {careersContentSection.careersContentHeading}
                  </h2>
                  <SafeHtmlParser htmlContent={careersContentSection?.careersContentText} />
                </Col>
              </Row>
            </Container>
          </section>
        )}

      <section className="pt-5  pt-lg-7" id="jobs">
        <Container>
          <Row>
            <Col>
              <h2 className=" text-center pb-5 text-uppercase">
                {careersCurrentVacancies.careersCurrentVacanciesHeading ||
                  "Our current vacancies"}
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              {data.allWpJobOpportunity.nodes.map((job) => (
                <div
                  className="p-4 mb-4"
                  style={{ background: "#EBEBEB", borderRadius: "12px" }}
                >
                  <Link className="black-link" to={`/careers/${job.slug}`}>
                    <h3 className="text-uppercase ">{job.title}</h3>
                  </Link>
                  <p className="inter-bold">Salary</p>
                  <p>{job.jobFields.salary}</p>
                  <p className="inter-bold">Features</p>
                  <Row className="">
                    <div>
                      {job.tags.nodes
                        .filter((item, index) => index < 7)
                        .map((feature, i) => (
                          <div
                            className={`px-3 py-2 text-center mb-3 d-inline-block
													  ${alternatingColor[i % alternatingColor.length]} me-3`}
                            style={{
                              background: "#EBEBEB",
                              borderRadius: "300px",
                            }}
                          >
                            <p
                              style={{ fontSize: "90%" }}
                              className="text-white pb-0 mb-0"
                            >
                              {feature.name}
                            </p>
                          </div>
                        ))}
                    </div>
                  </Row>
                </div>
              ))}
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                style={{ borderRadius: "0px" }}
                variant="secondary"
                href={
                  careersCurrentVacancies.careersCurrentVacanciesButton1.url ||
                  "#form"
                }
                className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
              >
                {careersCurrentVacancies.careersCurrentVacanciesButton1.title ||
                  "Submit CV"}
              </Button>
              <Button
                style={{ borderRadius: "0px" }}
                variant="outline-secondary"
                href={
                  careersCurrentVacancies.careersCurrentVacanciesButton2.url ||
                  "#someone"
                }
                className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
              >
                {careersCurrentVacancies.careersCurrentVacanciesButton2.title ||
                  " Refer someone"}
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      {careersLeftContentRightImage &&
        !checkPropertiesForNull(careersLeftContentRightImage, [
          "careersLeftContentHeading",
        ]) && (
          <div className="pt-5 pt-lg-7">
            <ImageSideContainer
              image={
                careersLeftContentRightImage.careersRightImage.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
              imageAlt={
                careersLeftContentRightImage.careersRightImage.node?.altText
              }
              title={careersLeftContentRightImage.careersLeftContentHeading}
              order="last"
              imagePadding=" pb-5 pb-lg-0    "
              text={careersLeftContentRightImage.careersLeftContentText}
            />
          </div>
        )}

      <section id="form" className="pb-5 pb-lg-0 pt-5 pt-lg-7">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <h2 className="text-center text-uppercase pb-4">
                {careersFormSection?.careersFormHeading ||
                  "Submit your details"}
              </h2>
              <div
                style={{ background: "#F9F9F9", borderRadius: "18px" }}
                className="p-md-5 p-4"
              >
                <CareerForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {careersContentCta &&
        !checkPropertiesForNull(careersContentCta, [
          "careersContentCtaHeading",
        ]) && (
          <section id="someone" className="py-5  py-lg-7 position-relative">
            <Container>
              <Row>
                <Col className="position-relative">
                  <h2 className="  pb-5 text-uppercase">
                    {careersContentCta.careersContentCtaHeading}
                  </h2>
                  <SafeHtmlParser htmlContent={careersContentCta?.careersContentCtaText} />
                </Col>
              </Row>
              {careersContentCta.careersContentCtaButton1 &&
                careersContentCta.careersContentCtaButton1.url && (
                  <Row>
                    <Col className="text-center">
                      <Button
                        as={Link}
                        style={{ borderRadius: "0px" }}
                        variant="secondary"
                        to={careersContentCta.careersContentCtaButton1.url}
                        className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
                      >
                        {careersContentCta.careersContentCtaButton1.title}
                      </Button>
                    </Col>
                  </Row>
                )}
            </Container>
          </section>
        )}
    </Layout>
  );
};

export default CareersPage;
